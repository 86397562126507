export const experienceData = [
    {
        id: 1,
        company: 'Zevk-ü Şifa',
        jobtitle: 'Dijital Pazarlama',
        startYear: '2023',
        endYear: 'Devam ediyor'
    },
    {
        id: 2,
        company: 'Colaksco',
        jobtitle: 'Sosyal Medya Pazarlama Projesi',
        startYear: '2023',
        endYear: 'Devam Ediyor'
    },
    {
        id: 3,
        company: 'Zevk-ü Şifa',
        jobtitle: 'Satış Danışmanı',
        startYear: '2017',
        endYear: '2020'
    },
]