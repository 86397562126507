// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    font-family: 'var(--primaryFont)', sans-serif;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer p {
    font-weight: 500;
    font-family: var(--primaryFont);
    font-size: 18px;
}

.footer p span {
    font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,YAAY;IACZ,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".footer {\n    font-family: 'var(--primaryFont)', sans-serif;\n    height: 60px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.footer p {\n    font-weight: 500;\n    font-family: var(--primaryFont);\n    font-size: 18px;\n}\n\n.footer p span {\n    font-size: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
