// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backToTop {
    position: fixed; 
    right: 10px;
    bottom: 60px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        right: -10px;
        bottom: 50px;
        font-size: 2.75rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/BackToTop/BackToTop.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;QACZ,kBAAkB;IACtB;AACJ","sourcesContent":[".backToTop {\n    position: fixed; \n    right: 10px;\n    bottom: 60px;\n    height: 30px;\n    font-size: 3rem;\n    z-index: 999;\n}\n\n.backToTop button {\n    outline: none;\n    border: none;\n    cursor: pointer;\n    background: none;\n    padding: 20px;\n}\n\n@media screen and (max-width: 800px) {\n    .backToTop { \n        right: -10px;\n        bottom: 50px;\n        font-size: 2.75rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
