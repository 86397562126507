export const educationData = [
    {
        id: 1,
        institution: 'Oktay Olcay Yurtbay Anadolu Lisesi',
        course: 'Lise',
        startYear: '2019',
        endYear: '2022'
    },
    {
        id: 2,
        institution: 'Sakarya Üniversitesi ',
        course: 'İşletme',
        startYear: '2023',
        endYear: '2027'
    },
    {
        id: 3,
        institution: 'Anadolu Üniversitesi',
        course: 'Bilgisayar Programcılığı',
        startYear: '2024',
        endYear: 'Devam ediyor'
    },
]