// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primaryFont: 'Poppins', sans-serif;
    /* --primaryFont: 'Montserrat', sans-serif; */
    /* --primaryFont: 'Raleway', sans-serif; */
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,6CAA6C;IAC7C,0CAA0C;AAC9C","sourcesContent":[":root {\n    --primaryFont: 'Poppins', sans-serif;\n    /* --primaryFont: 'Montserrat', sans-serif; */\n    /* --primaryFont: 'Raleway', sans-serif; */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
