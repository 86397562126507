import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Sosyal Medya Pazarlama Projesi',
        projectDesc: 'Bu proje sosyal medyadan elde edilebilen potansiyel kitleyi bir hesapta birleştirip, pazarlama hedeflerini gerçekleştirmektir.',
        tags: ['Pazarlama', 'Medya'],
        code: '',
        demo: '',
        image: one
    },

]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/